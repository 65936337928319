@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  text-decoration: none!important;
}

.btn-primary, .page-item.active .page-link, .v-avatar {
  background-color: #0F4372!important
}

.tooltip-white > .tooltip-inner,.tooltip-white > .tooltip-arrow {
  background-color: #fff;
  --bs-tooltip-bg: #fff;
}

.btn-outline-primary {
  --bs-btn-color: #0F4372!important;
  --bs-btn-border-color: #0F4372 !important;
  --bs-btn-hover-color: #fff ;
  --bs-btn-hover-bg: #0F4372 !important;
  --bs-btn-hover-border-color: #0F4372 !important;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0F4372 !important;
  --bs-btn-active-border-color: #0F4372 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0F4372 !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0F4372 !important;
  --bs-gradient: none;
}
  
.list-group-item{
  background-color: #0F4372 !important;
  color: #fff!important;
  border-right-color: #fff!important;
}

.download-overlay .tooltip-arrow, .download-overlay .tooltip-inner{
  background-color: #fff!important;
}

.v-icons, .nav-search {
  cursor: pointer;
}

.v-table{
  max-height: 80vh;
  overflow-y: scroll;
}

.pv-blackdrop > .block-ui-container {
  background-color: #645f5f;
  opacity: 0.9;
}

.pv-blackdrop .block-ui-message-container .block-ui-message {
  color: #fff;
  font-size: 30px;
}

.nav-search{
  font-size: 22px;
  padding: 10px;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.modal-actions button {
  margin-left: 10px;
  padding: 5px 10px;
}



/* CUSTOM */

:where(.css-dev-only-do-not-override-11lehqq).ant-collapse>.ant-collapse-item{
  border-bottom: none;
  background: #fafafa;
  border-bottom: 1px solid #f1f1f1;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  padding: 5px;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-collapse .ant-collapse-content {
  color: rgba(0, 0, 0, 0.88);
  background-color: #ffffff;
  margin: 15px;
  border-top: none;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-collapse{
  border:none
}
.modal-backdrop.show{
  z-index:0;
}

.sheet-summary-list-group .list-group-item{
  color: #212529 !important;
  background-color: #fff !important;
}

.iq-sidebar{
  z-index: 999 !important;
}

.excel-sheet-modal .modal-dialog{
  min-width: 100%;
}

.modal .modal-content form.login-content{
  height: auto;
}
.profile-header .btn-link{
  text-decoration: none;
}
.profile-header .btn-link:hover {
  color: #0d6efd !important;
}

#user-list-table .btn-link:hover{
  color: inherit !important;
}

.login-content .floating-label .checkbox-group label {
  position: relative;
  top: 8px;
}

/*Progressbar*/
.steps-progressbar .circle {
  width: 40px;
  height: 40px;
  border: 2px solid #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
}

.steps-progressbar .step-number {
  font-size: 1rem;
  color: #007bff;
}

.steps-progressbar .step-label {
  margin-top: 8px;
  font-size: 1rem;
  color: #007bff;
}

.steps-progressbar .line {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  z-index: -1;
}

.steps-progressbar .bg-primary {
  background-color: #007bff !important;
}

.steps-progressbar .bg-light {
  background-color: #e9ecef !important;
}

.steps-progressbar .progress-line {
  height: 2px;
  top: 20px;  /* Aligns the line with the circle vertically */
  z-index: 1;
  position: absolute;
}
/*Progressbar*/
