.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f0f0f0;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
}

.modal-actions button {
    margin-left: 10px;
    padding: 5px 10px;
}
