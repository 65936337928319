.custom-modal-height .modal-dialog {
  margin: 0;
  display: flex;
  align-items: center; 
  justify-content: center;
  min-height: 100vh; 
}

.custom-modal-height .modal-content {
  border-radius: 10px;
  max-height: calc(100vh - 20px); 
}

.custom-modal-height .modal-header {
 padding: 10px;
}
.badge-custom {
  max-width: 150px;
  position: absolute;
  right: 10px;
}

.copy-button {
  right: 10px; 
  top: 80%;
  transform: translateY(-50%); 
  border: .10px solid #ccc;
  border-radius: 20%;
  padding: 2px 4px;
  text-decoration: none;
  background: none;
  
}

.copy-button:hover {
  background-color:#2e2e2e;
  border: .10px solid #ccc;
}


.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 2px solid #0d4473;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color:  #0d4473;
  border: 2px solid #0d4473;
}

.custom-checkbox input[type="checkbox"]::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  display: block;
}


.position-relative {
  position: relative;
}

.alert-overlay-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


