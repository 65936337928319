.forex-container {
    margin: 8vh auto;
    width:50%;
    background-color: white;
    padding: 2rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
} 

.date-pick{
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
    justify-content: space-around;
}

.add-button{
    width: 2.5em;
    height: 2.5em;
    margin-left: 2em;

}

.currency-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.currency-row .form-group {
    flex: 1;
    margin-right: 10px;
}

.currency-row .remove-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}

.add-currency-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
}

.save-btn {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-top: 20px;
}  