.currency-dropdown {
    display: flex;
    align-items: center;
}

.currency-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.currency-image {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}