.indexedStep {
  color: rgb(190, 190, 190);
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.indexedStep.accomplished {
  background-color: #007bff;
  color: white;
  border-style: none;
}

.indexedStep.completed {
    background-color: #007bff;
    color: white;
  }

.indexedStep > svg {
  font-size: 11px;
}

.indexedStep .mt-14 {
  position: absolute;
  font-size: 12px;
  text-align: center;
}

.RSPBprogressBar {
  height: 2px;
  width: 200px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgb(207, 207, 207);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #007bff;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .indexedStep {
    width: 15px;
    height: 15px;
    font-size: 6px;
  }
}

.single-step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


