.chatbotmodal {
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
}

.closing {
    opacity: 0;
    transform: scale(0.9);
}

.chatbox-header {
    background-color: #0F4372;
    border-radius: 0.25rem 0.25rem 0 0;
}

.chatbox-header h5 {
    color: white;
}

.chatbox-header .btn-link {
    font-size: 1.5rem;
    text-decoration: none;
}

.chatbox-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    height: 280px;
}

.message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.message.user {
    flex-direction: row-reverse;
}

.message.other {
    flex-direction: row;
}

.message-text {
    background-color: #f1f1f1;
    padding: 8px;
    border-radius: 15px;
    margin-left: 10px;
    max-width: 100%;
}

.message.user .message-text {
    margin-left: 0;
    margin-right: 10px;
}

.message.other .message-text {
    margin-right: 10px;
}

.chatbotmodal .avatar {
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatbotmodal .input-group-append .btn {
    border-radius: 0 0.25rem 0.25rem 0;
}

.chatbotmodal input.form-control {
    border: none;
    /* Removes the border */
    border-radius: 0;
    /* Removes any border-radius */
    outline: none;
    /* Removes the outline on focus */
    box-shadow: none;
    /* Removes any box shadow */
    padding-left: 0;
    /* Adjust padding to align text */
    background-color: transparent;
    /* Makes the background transparent */
}

.chatbotmodal input.form-control::placeholder {
    color: #6c757d;
    font-style: italic;
}

.chatbotmodal input.form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.messenger-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: transform 0.3s ease-in-out;
}

.messenger-icon:hover {
    transform: scale(1.1);
}

.chat-size {
    font-size: 8px;
}

.chat-close-button{
    font-size: 2rem !important;
    width: 2em;
}

.notification-dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid white; /* Optional border to make it stand out */
}

