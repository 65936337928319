

.step-container-horizontal {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow-x: auto; /* Allows horizontal scrolling for many steps */
  padding: 10px;
}

.step-horizontal {
  display: flex;
  align-items: center;
}

.step-icon {
  margin-right: 10px;
}

.step-label {
  display: flex;
  align-items: center;
  gap: 30px;
  white-space: nowrap;       
  overflow: hidden;         
  text-overflow: ellipsis; 
  max-width: 150px;  
  font-weight: 500;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 2px solid #0d4473;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #0d4473;
  border: 2px solid #0d4473;
}

.custom-checkbox input[type="checkbox"]::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  display: block;
}

.step-arrow-horizontal {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.arrow-line-horizontal {
  width: 30px;
  height: 2px;
  background-color: #ccc;
}
